import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import suivitechnique from '../../images/suivitechnique.svg';

import { breakpoints, colors, dimensions } from '../../styles/variables';
import { RenderMarkdown } from '../atoms';

const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 20px;
  box-shadow: 0px 0px 6px ${colors.purple};
  margin-top: 25px;
  margin-bottom: 42px;
  position: relative;

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-left: 17%;
  }
`;

const ContentWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-left: 92px;
    padding-right: 92px;
  }
`;

const MonitoringWrapper = styled(ContentWrapper)`
  display: flex;
  gap: 10px;
`;

const HeaderContainer = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CategoryName = styled.p`
  margin: 25px 0 11px 0;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 41px;
  text-align: center;
  line-height: 50px;
  margin: 0;

  ::after {
    display: block;
    margin: ${dimensions.space.base} auto 24px auto;
    content: '';
    height: 4px;
    width: 50%;
    background-color: ${colors.pink};
  }
`;

const TargetText = styled.p`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  margin: 28px 0;

  strong {
    color: ${colors.pink};
  }
`;

const SectionHeading = styled(ContentWrapper.withComponent('h3'))<{ naked?: boolean }>`
  font-size: 22px;
  line-height: 27px;
  font-weight: 500;
  position: relative;
  margin: 0;

  ::after {
    background-color: ${({ naked }) => (naked ? 'transparent' : colors.lila)};
    content: '';
    display: block;
    height: 1px;
    width: 218px;
    position: absolute;
    margin-top: 5px;
    left: 0;
  }
`;

const Content = styled(RenderMarkdown)`
  margin: 16px 0 24px 0;

  ul {
    padding: 0;
    margin: 0;
    list-style-position: inside;
    list-style-type: none;
  }

  li {
    display: flex;

    ::before {
      content: '';
      display: block;
      background-position: center;
      // Pour le moment, j'ai intégré le svg comme ça ... à voir.
      background-image: url('data:image/svg+xml;utf8,<svg stroke="black" fill="%23FC1F95" stroke-width="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>');
      background-size: 12px;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      margin-top: 5px;
      flex-shrink: 0;
      border: 1px solid ${colors.pink};
      border-radius: 50%;
    }
  }
`;

const CardFooter = styled.div`
  background-color: ${colors.lightLila};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  padding-top: 24px;

  @media screen and (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }
`;

const MonitoringIcon = styled.img`
  max-width: 150px;
`;

const GoBack = styled(Link)`
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
`;

const DownloadButton = styled.a`
  font-size: 13px;
  font-weight: bold;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 24px 0;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 25px 92px 0;
  }
`;

export type ProductDetailProps = {
  id: string;
  name: string;
  commisionning: string;
  target: string;
  conditions: string;
  services: string;
  monitoring: string;
  category: { title: string };
  file: {
    mime: string;
    url: string;
    name: string;
  };
};

const mapPdf = {
  'santé tpe': {
    name: 'Looma_01 Sante TPE.pdf',
    path: '/Looma_01_Sante_TPE.pdf',
  },
  'santé pme': {
    name: 'Looma_02 Sante PME.pdf',
    path: '/Looma_02_Sante_PME.pdf',
  },
  'santé modulaire entreprise': {
    name: 'Looma_03 Santé Modulaire Entreprise.pdf',
    path: '/Looma_03_Sante_Modulaire_Entreprise.pdf',
  },
  'santé régimes à options entreprises': {
    name: 'Looma_04 Santé Régimes A Options Entreprises.pdf',
    path: '/Looma_04_Sante_Regimes_A_Options_Entreprises.pdf',
  },
  'prévoyance modulable entreprises': {
    name: 'Looma_05 Prévoyance Modulable Entreprises.pdf',
    path: '/Looma_05_Prevoyance_Modulable_Entreprises.pdf',
  },
  'prévoyance ccn': {
    name: 'Looma_06 Prévoyance CCN.pdf',
    path: '/Looma_06_Prevoyance_CCN.pdf',
  },
  'santé tns': {
    name: 'Looma_07 Santé TNS.pdf',
    path: '/Looma_07_Sante_TNS.pdf',
  },
  'prévoyance modulable indépendants': {
    name: 'Looma_08 Prévoyance Modulable Indépendants.pdf',
    path: '/Looma_08_Prevoyance_Modulable_Indépendants.pdf',
  },
};

export default function ProductDetail({
  name,
  category,
  target,
  services,
  conditions,
  commisionning,
  monitoring,
  file,
}: ProductDetailProps) {
  // TODO : A décommenter quand strapi mis en place
  // const [blobPdf, setBlobPdf] = useState(null);

  // const fetchPdf = () => {
  //   if (!file || !file.mime || !file.url) return;
  //   fetch(`${process.env.API_URL}${file.url}`, {
  //     method: 'GET',
  //     headers: { 'Content-Type': file.mime },
  //   })
  //     .then((response) => response.blob())
  //     .then((blob) => setBlobPdf(window.URL.createObjectURL(new Blob([blob]))));
  // };

  // useEffect(() => {
  //   fetchPdf();
  // }, []);

  return (
    <Card>
      <ActionContainer>
        <GoBack to="/solutions">&larr; Retour</GoBack>
        {mapPdf[name.toLowerCase()] && (
          <DownloadButton href={mapPdf[name.toLowerCase()].path} download={mapPdf[name.toLowerCase()].name}>
            Télécharger le PDF
          </DownloadButton>
        )}
        {/* // TODO : A décommenter quand strapi mis en place */}
        {/* {blobPdf && (
          <DownloadButton href={blobPdf} download={file.name}>
            Télécharger le PDF
          </DownloadButton>
        )} */}
      </ActionContainer>
      <HeaderContainer>
        <CategoryName>
          <strong>Vos solutions : </strong>
          {category.title}
        </CategoryName>
        <Title>{name}</Title>
      </HeaderContainer>
      <ContentWrapper>
        <TargetText>
          <RenderMarkdown disableParagraph>{target}</RenderMarkdown>
        </TargetText>
      </ContentWrapper>
      <SectionHeading>Tarifs & conditions :</SectionHeading>
      <ContentWrapper>
        <Content>{conditions}</Content>
      </ContentWrapper>
      <SectionHeading>Services & options :</SectionHeading>
      <ContentWrapper>
        <Content>{services}</Content>
      </ContentWrapper>
      <CardFooter>
        <div
          css={css`
            @media screen and (min-width: ${breakpoints.xl}) {
              > * {
                padding-right: 0 !important;
              }
            }
          `}
        >
          <SectionHeading>Commissionnement</SectionHeading>
          <ContentWrapper>
            <Content>{commisionning}</Content>
          </ContentWrapper>
        </div>
        <div
          css={css`
            @media screen and (min-width: ${breakpoints.xl}) {
              > * {
                padding-left: 0 !important;
              }
            }
          `}
        >
          {monitoring && (
            <>
              <SectionHeading naked>Suivi technique portefeuilles</SectionHeading>
              <MonitoringWrapper>
                <Content>{monitoring}</Content>
                <MonitoringIcon src={suivitechnique} alt="Logo monitoring" />
              </MonitoringWrapper>
            </>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}
